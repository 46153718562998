<template>
  <div v-bind="$attrs" v-on="$listeners" @click.stop="workshiftItemClicked">
    <div class="workshift-name">{{ workShiftDescription }}</div>
  </div>
</template>

<script>
import { SHOW_WORK_SHIFT_DETAIL_EVENT } from "@/pages/responsible/planing/workshift/data/events";

export default {
  props: {
    workShiftCode: {
      type: [String, Number],
      default: "",
    },
    workShiftDescription: {
      type: [String, Number],
      default: "",
    },
    date: {
      type: [Date],
      default() {
        return new Date();
      },
    },
  },
  methods: {
    workshiftItemClicked() {
      EventBus.fire(SHOW_WORK_SHIFT_DETAIL_EVENT, {
        code: this.workShiftCode,
        description: this.workShiftDescription,
        date: this.date,
      });
    },
  },
};
</script>

<style scoped>
.workshift-name {
  font-size: 12px;
}
</style>
